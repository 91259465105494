.react-calendar { 
    width: 400px;
    max-width: 100%;
    background-color: transparent!important;
    border-radius: 8px;
    font-family: 'Lexend';
    line-height: 1.125em;
    border: 0
   }
   abbr[title] {
    text-decoration: none;
   }
   .react-calendar__month-view__days__day--weekend {
   }
   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    border-radius: 6px;
    cursor: default!important;
   }
   .react-calendar__tile--now {
    background: black;
    border-radius: 6px;
    font-weight: bold;
    cursor: default!important;
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
    background: black;
    border-radius: 6px;
    font-weight: bold;
    cursor: default!important;
   }
   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
   }
   .react-calendar__tile--active {
    background: black;
    border-radius: 6px;
    font-weight: bold;
    cursor: default!important;
   }
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
    background: black;
   }

.MuiPickersCalendarHeader-label {
    letter-spacing: -0.5px;
    font-size: 12pt;
}