
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiButton-root	{
  text-transform: capitalize!important;
  box-shadow: none!important;
  border-radius: 8px!important;
}

.styled-bg-container {
  background-color: white;
  /* Apply linear gradient background */
  background-image: url('assets/bg-blurry.png'); 
  background-repeat: no-repeat;
  background-size: "80%";
  /* Set background position to top left */
  background-position: top left;
  /* Fix background attachment to prevent scrolling */
  background-attachment: fixed;
  /* Ensure the background covers the entire viewport */
  height: 100vh;
  margin: 0;
}

.styled-bg-container::after {
  content: '';
  position: absolute;
  top: 8%; /* Adjust the top position as needed */
  left: 8%; /* Adjust the left position as needed */
  width: 61px; /* Adjust the width as needed */
  height: 61px; /* Adjust the height as needed */
  background-image: url('assets/logo-white.png'); /* Specify the path to your overlay image */
  background-repeat: no-repeat;
  background-size: contain; /* You can adjust the background size as per your preference */
  z-index: 1; /* Ensure it's above the main background */
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


@keyframes movingBackground {
  0% {
    background-color: #ff840000;
  }
  50% {
    background-color: #ff840050;
  }
  100% {
    background-color: #ff840080;
  }
}

.processing-row {
  animation: movingBackground 3s linear infinite;
}

.done-row {
  background-color: #D1FFBD;
}

.error-row {
  background-color: #EF9A9A;
}

.default-row {
  background-color: white;
}